import { useContext, createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttpClient from './hooks/useHttpClient';

interface AuthContextType {
  loginAuth: (data: { username: string; password: string }) => Promise<void>;
  logoutAuth: () => void;
  getToken: () => string | null;
}

const AuthContext = createContext<AuthContextType>({
  loginAuth: async () => {},
  logoutAuth: () => {},
  getToken: () => null,
});

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const httpClient = useHttpClient();

  const loginAuth = async (data: { username: string; password: string }) => {
    try {
      const res = await httpClient.post('/api/auth/login', data);
      const resData = res.data;

      if (resData.token) {
        localStorage.setItem('token', resData.token);
        navigate('/'); // Navigate to the home page or another route after login
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logoutAuth = () => {
    localStorage.removeItem('token');
    navigate('/login'); // Redirect to login after logout
  };

  const getToken = (): string | null => {
    return localStorage.getItem('token');
  };

  return (
    <AuthContext.Provider value={{ loginAuth, logoutAuth, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
