import React from 'react';
import { Container, Button, Title, Text, Group, Paper } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const ProviderPreRegistrationLandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate('/provider/register/practice');
  };

  const handlePhysicianClick = () => {
    navigate('/provider/register/physician');
  };

  return (
    <Container style={{ textAlign: 'center', marginTop: '100px' }}>
      <Paper withBorder shadow="md" p="xl" radius="md">
        <Title order={1}>
          Ryze Provider Network <br /> Pre-Registration
        </Title>
        <Text mt="md" size="lg">
          We are delighted to have you as part of our growing community of independent physicians.
          <br />
          <br />
          With Ryze, you have the opportunity to help your fellow providers as you grow your business.
          <br />
          <br />
          Please let us know if you're an independent provider or a practice owner.
        </Text>

        <Group justify="center" mt="xl">
          <Button
            size="lg"
            onClick={handlePhysicianClick}
            style={{ backgroundColor: '#20B2AA', color: '#fff' }}
          >
            I am a physician
          </Button>
          <Button
            size="lg"
            onClick={handlePracticeClick}
            variant="outline"
            style={{ borderColor: '#20B2AA', color: '#20B2AA' }}
          >
            I represent a practice
          </Button>
        </Group>
      </Paper>
    </Container>
  );
};

export default ProviderPreRegistrationLandingPage;
