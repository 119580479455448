import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextInput, Button, Container, Grid, Paper, Title, Select, Text } from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { STATES_ARRAY } from '../../types/members.types';
import useHttpClient from '../hooks/useHttpClient';
import { notifications } from '@mantine/notifications';

interface NewsletterFormProps {
  onFormSubmit?: (data: any) => void;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  onFormSubmit,
}) => {
  const { control, register, handleSubmit, reset } = useForm();
  const httpClient = useHttpClient();

  // Initial priorities list
  const initialPriorities = [
    { id: '1', label: 'Affordable Costs' },
    { id: '2', label: 'Ease of use and navigation (doctor and hospital choice)' },
    { id: '3', label: 'Billing clarity and transparency' },
  ];
  
  const [priorities, setPriorities] = useState(initialPriorities);
  
  const onDragEnd = (result) => {
    if (!result.destination) return; // If the item is dropped outside the list, do nothing
    const updatedPriorities = Array.from(priorities);
    const [reorderedItem] = updatedPriorities.splice(result.source.index, 1);
    updatedPriorities.splice(result.destination.index, 0, reorderedItem);
    setPriorities(updatedPriorities);
  };

  const onSubmit = async (data: any) => {
    const submissionData = {
      ...data,
      priorities: priorities.map((priority) => priority.label), // Submit priorities in the ranked order
    };
    
    try {
      const response = await httpClient.post('/api/newsletter', submissionData);
      if (onFormSubmit) {
        onFormSubmit(response.data);
      }
      notifications.show({
        title: 'Success',
        message: 'Successfully signed up for the newsletter!',
        color: 'green',
        position: 'bottom-right',
      });
      reset();  // Reset the form after successful submission
    } catch (error) {
      console.error('Error submitting newsletter form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit the newsletter form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Newsletter Signup</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                type="email"
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Practice Name"
                {...register('practicename')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Rank Your Priorities</Title>
          <Text mt="xs" size="sm" color="dimmed">
            Drag and drop to rank your health insurance priorities from top to bottom.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="priorities">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {priorities.map((priority, index) => (
                        <Draggable key={priority.id} draggableId={priority.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                padding: '8px',
                                margin: '4px 0',
                                backgroundColor: '#f1f1f1',
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'grab',
                                userSelect: 'none',
                                ...provided.draggableProps.style,
                              }}
                            >
                              <span>{priority.label}</span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid.Col>
          </Grid>
        </Paper>

        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default NewsletterForm;
