import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Grid, Button, Group, Title } from '@mantine/core';
import PersonalInformationTab from './PersonalInformationTab';
import FamilyMemberManagementTab from './FamilyManagementTab';
import HomeIcon from '../HomeIcon';
import LogoutIcon from '../LogoutIcon';
import DarkModeToggle from '../DarkModeToggle';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import PrivacyPolicyModal from './PrivacyPolicy';

const InsuranceEmployeePortal: React.FC = () => {
  const { member } = useInsuranceMemberContext();
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>

      <Title
        order={1}
        style={{
          textAlign: 'center',
          marginBottom: '2rem',
        }}
      >
        Employee Portal
      </Title>

      <Group justify="right" mb="md">
        <Button color="green" onClick={() => setModalOpened(true)}>
          Complete Registration
        </Button>
      </Group>

      <PrivacyPolicyModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      />

      <Tabs defaultValue="personal-info">
        <Tabs.List>
          <Tabs.Tab value="personal-info">Personal Info</Tabs.Tab>
          <Tabs.Tab value="family-member-management">
            Family Member Management
          </Tabs.Tab>
          <Tabs.Tab value="copay-deductible-tracking">
            Copay & Deductible Tracking
          </Tabs.Tab>
          <Tabs.Tab value="financial-statements">Financial Statements</Tabs.Tab>
          <Tabs.Tab value="settings">Settings</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="personal-info">
          {member && <PersonalInformationTab />}
        </Tabs.Panel>
        <Tabs.Panel value="family-member-management">
          {member && <FamilyMemberManagementTab />}
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default InsuranceEmployeePortal;
