import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput, Button, Container, Grid } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';

interface EmployeeFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ initialData, onFormSubmit }) => {
  const { enrollmentcode } = useParams<{ enrollmentcode: string }>();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: initialData,
  });
  
  const [loading, setLoading] = useState(false);

  // Fetch employee data from the database using the enrollmentcode
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/api/subscriber/enrollment/${enrollmentcode}`);
        if (response.ok) {
          const data = await response.json();
          reset(data); // Populate the form with fetched data
        } else {
          throw new Error('Failed to fetch employee data');
        }
      } catch (error) {
        console.error('Error fetching employee data', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to load employee data',
          color: 'red',
          position: 'bottom-right',
        });
      } finally {
        setLoading(false);
      }
    };

    if (enrollmentcode) {
      fetchData();
    }
  }, [enrollmentcode, reset]);

  const onSubmit = async (data: any) => {
    try {
      const response = await fetch(`/api/subscriber/enrollment/${enrollmentcode}`, {
        method: 'PUT', // Send a PUT request
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        notifications.show({
          title: 'Success',
          message: 'Form updated successfully',
          color: 'green',
          position: 'bottom-right',
        });

        if (onFormSubmit) {
          onFormSubmit(result); // Pass updated data to the parent component
        }
      } else {
        throw new Error('Failed to update form');
      }
    } catch (error) {
      console.error('Error updating form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="First Name"
              required
              {...register('firstname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Username (Email)"
              required
              {...register('email', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Password"
              type="password"
              required
              {...register('password')}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
        </Grid>
        <Button type="submit" mt="md" disabled={loading}>
          {loading ? 'Loading...' : 'Update'}
        </Button>
      </form>
    </Container>
  );
};

export default EmployeeForm;
