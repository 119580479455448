import React from 'react';
import { Container, Paper, Title, Text } from '@mantine/core';
import { useLocation } from 'react-router-dom';

const ConfirmationPage: React.FC = () => {
  const location = useLocation();
  const { addUserResult } = location.state || {};

  // Determine the message to display based on addUserResult
  const getMessage = () => {
    if (!addUserResult) return null; // No result to display

    const { userExists, privilegesInserted, userInserted, privilegesExist } = addUserResult;

    if (userExists && privilegesExist) {
      // User and privileges both already existed
      return (
        <Text ta="center" mt="md">
          You are already enrolled in this service.
        </Text>
      );
    }

    if (userExists && privilegesInserted) {
      // User exists, but privileges were added (service enrollment)
      return (
        <Text ta="center" mt="md">
          An account with your email already exists. Your enrollment for the new service has been successfully processed.
        </Text>
      );
    }

    return null; // Fallback if none of the conditions match
  };

  // Determine if we should show the email verification message
  const showVerificationMessage = () => {
    const { privilegesExist } = addUserResult || {};
    return !privilegesExist; // Only show if both user and privileges don't exist
  };

  return (
    <Container>
      <Paper shadow="md" p="xl" withBorder>
        <Title order={2} ta="center" mb="sm">
          Thank You for Registering!
        </Title>
        {showVerificationMessage() && (
          <Text ta="center">Please check your email for verification to complete your signup.</Text>
        )}

        {getMessage()}
      </Paper>
    </Container>
  );
};

export default ConfirmationPage;
