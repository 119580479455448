import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MantineProvider, Container, Title } from '@mantine/core';
import AdminPortal from './components/AdminPortal';
import MemberPortal from './components/MemberPortal';
import { MemberProvider } from './components/MemberContext';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import AuthProvider from './components/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import PhysicianForm from './components/InsurancePortal/PhysicianForm';
import ClinicForm from './components/InsurancePortal/ClinicForm';
import InsuranceMemberPortal from './components/InsurancePortal/InsuranceMemberPortal';
import EmployeeForm from './components/InsurancePortal/EmployeeForm';
import InsuranceAdminPortal from './components/InsurancePortal/InsuranceAdminPortal';
import { OrganizationProvider } from './components/InsurancePortal/OrganizationContext';
import { InsuranceMemberProvider } from './components/InsurancePortal/InsuranceMemberContext';
import InsuranceEmployeePortal from './components/InsurancePortal/InsuranceEmployeePortal';
import ConfirmationPage from './components/InsurancePortal/ConfirmationPage';
import PhysicianEmailVerificationPage from './components/InsurancePortal/PhysicianEmailVerificationPage';
import ClinicEmailVerificationPage from './components/InsurancePortal/ClinicEmailVerificationPage';
import ProviderPracticeForm from './components/ProviderPortal/ProviderPracticeForm';
import ProviderPhysicianForm from './components/ProviderPortal/ProviderPhysicianForm';
import ProviderPhysicianEmailVerificationPage from './components/ProviderPortal/ProviderPhysicianEmailVerificationPage';
import ProviderPracticeEmailVerificationPage from './components/ProviderPortal/ProviderPracticeEmailVerificationPage';
import InsurancePreRegistrationLandingPage from './components/InsurancePortal/InsurancePreRegistrationLandingPage'
import ProviderPreRegistrationLandingPage from './components/ProviderPortal/ProviderPreRegistrationLandingPage';
import IpaMemberSignup from './components/WecareMemberSignup';
import IpaMemberEmailVerificationPage from './components/WecareEmailVerificationPage';
import WecareMemberApprovalPage from './components/WecareMemberApprovalPage';
import InsuranceMemberApprovalPage from './components/InsurancePortal/InsuranceMemberApproval';
import NewsletterForm from './components/InsurancePortal/NewsletterForm';

const App: React.FC = () => {
  return (
    <MemberProvider>
      <Router>
        <AuthProvider>
          <MantineProvider>
            <Notifications />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<ProtectedRoute requiredPrivilege='admin'/>}>
                <Route
                  path="/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Admin Portal
                      </Title>
                      <AdminPortal />
                    </Container>
                  }
                />
                <Route
                  path="/member/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Member Portal
                      </Title>
                      <MemberPortal />
                    </Container>
                  }
                />
                <Route
                  path="/member/:id"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Member Portal
                      </Title>
                      <MemberPortal />
                    </Container>
                  }
                />
                <Route
                  path="/wecare/admin/approve"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        WeCare IPA Member Approval
                      </Title>
                      <WecareMemberApprovalPage />
                    </Container>
                  }
                />
                <Route
                  path="/insurance/admin/approve/physician"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Ryze Insurance Member Approval
                      </Title>
                      <InsuranceMemberApprovalPage />
                    </Container>
                  }
                />
              </Route>
              <Route
                path="/member/error"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Error
                    </Title>
                  </Container>
                }
              />
              <Route
                path="/wecare/register/member"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      WeCare IPA Registration
                    </Title>
                    <IpaMemberSignup />
                  </Container>
                }
              />
              <Route
                path="/wecare/register/member/verify/:enrollmentcode"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <IpaMemberEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="insurance/register/physician"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '0.5rem' }}
                    >
                      Ryze Health Insurance
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Physician Pre-Registration
                    </Title>
                    <PhysicianForm />
                  </Container>
                }
              />
              <Route
                path="insurance/register/organization"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '0.5rem' }}
                    >
                      Ryze Health Insurance
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Independent Practice Pre-Registration
                    </Title>
                    <ClinicForm />
                  </Container>
                }
              />
              <Route
                path="insurance/register/employee/:enrollmentcode"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Employee Signup
                    </Title>
                    <EmployeeForm />
                  </Container>
                }
              />
              <Route
                path="insurance/register/newsletter"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '0.5rem' }}
                    >
                      Ryze Health Insurance
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Sign Up for Our Newsletter for More Information
                    </Title>
                    <NewsletterForm />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/confirmation"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Confirmation
                    </Title>
                    <ConfirmationPage />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/physician/verify/:enrollmentcode"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <PhysicianEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/organization/verify/:enrollmentcode"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <ClinicEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="insurance/member/"
                element={
                  <OrganizationProvider>
                  <InsuranceMemberProvider>
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <InsuranceMemberPortal />
                    </Container>
                  </InsuranceMemberProvider>
                  </OrganizationProvider>
                }
              />
              <Route
                path="insurance/admin/"
                element={
                  <OrganizationProvider>
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{
                          textAlign: 'center',
                          marginBottom: '2rem',
                        }}
                      >
                        Admin Portal
                      </Title>
                      <InsuranceAdminPortal />
                    </Container>
                  </OrganizationProvider>
                }
              />
              <Route
                path="insurance/employee/"
                element={
                  <InsuranceMemberProvider>
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <InsuranceEmployeePortal />
                    </Container>
                  </InsuranceMemberProvider>
                }
              />
              <Route
                path="/provider/register/physician"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '0.5rem' }}
                    >
                      Ryze Provider Network 
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Physician Pre-Registration
                    </Title>
                    <ProviderPhysicianForm />
                  </Container>
                }
              />
              <Route
                path="/provider/register/practice"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Ryze Provider Network 
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Independent Practice Pre-Registration
                    </Title>
                    <ProviderPracticeForm />
                  </Container>
                }
              />
              <Route
                path="/provider/register/confirmation"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Confirmation
                    </Title>
                    <ConfirmationPage />
                  </Container>
                }
              />
              <Route
                path="/provider/register/physician/verify/:enrollmentcode"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <ProviderPhysicianEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="/provider/register/practice/verify/:enrollmentcode"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <ProviderPracticeEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                    </Title>
                    <InsurancePreRegistrationLandingPage />
                  </Container>
                }
              />
              <Route
                path="/provider/register/"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                    </Title>
                    <ProviderPreRegistrationLandingPage />
                  </Container>
                }
              />
            </Routes>
          </MantineProvider>
        </AuthProvider>
      </Router>
    </MemberProvider>
  );
};

export default App;
