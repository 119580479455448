import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Grid,
  Group,
  Title,
  Table,
  ActionIcon,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { Plus, Lock, Pencil } from 'tabler-icons-react';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';
import './css/EmployeeManagementTab.css';
import { OrganizationMember } from '../../types/subscribermember.types';

interface FormValues {
  members: OrganizationMember[];
}

const EmployeeManagementTab: React.FC = () => {
  const { organizationId } = useOrganizationContext();
  const { control, register, getValues, setValue, handleSubmit, reset } =
    useForm<FormValues>({
      defaultValues: { members: [] },
    });

  const { fields, append } = useFieldArray({
    control,
    name: 'members',
  });

  const [selectedMemberIndex, setSelectedMemberIndex] = useState<number | null>(
    null,
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNewMember, setIsAddingNewMember] = useState(false);
  const httpClient = useHttpClient();

  const fetchMembers = async () => {
    try {
      const response = await httpClient.get(
        `/api/subscriber/organization/${organizationId}`,
      );
      const fetchedMembers: OrganizationMember[] = response.data;

      const membersWithFamilies = fetchedMembers
        .filter((member) => member.primarysubscriber === null)
        .map((primaryMember) => ({
          ...primaryMember,
          birthdate:
            primaryMember.birthdate === '' ? null : primaryMember.birthdate,
          familyMembers: fetchedMembers
            .filter((member) => member.primarysubscriber === primaryMember.id)
            .map((familyMember) => ({
              ...familyMember,
              birthdate:
                familyMember.birthdate === '' ? null : familyMember.birthdate,
            })),
        }));
      console.log(membersWithFamilies);

      reset({ members: membersWithFamilies });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch members',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const handleAddMember = () => {
    append({
      firstname: '',
      lastname: '',
      birthdate: null, // Default to null
      primarysubscriber: null,
      familyMembers: [],
      subscriberrelationship: 'Primary',
      enrollmentstatus: 'Pending',
      email: null,
    });

    // Select the new member for editing
    setSelectedMemberIndex(fields.length);
    setIsAddingNewMember(true);
    setIsEditing(true);
  };

  const handleNewMemberSubmit = async (data: FormValues) => {
    const newMember = data.members[fields.length - 1];

    const memberToSubmit = {
      ...newMember,
      organizationId, // Send the organizationId with the POST request
      familyMembers: undefined,
    };

    try {
      await httpClient.post('/api/subscriber/employee', memberToSubmit);
      fetchMembers();
      setIsAddingNewMember(false);
      setSelectedMemberIndex(null);
      notifications.show({
        title: 'Success',
        message: 'New member added successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to add new member',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const onSubmit = async (data: FormValues) => {
    const membersToSubmit = data.members.map((member) => {
      const { familyMembers, ...memberWithoutFamily } = member;
      return memberWithoutFamily;
    });

    try {
      const updateTasks = membersToSubmit.map((member) => {
        if (member.id) {
          return httpClient.put(`/api/subscriber/${member.id}`, member);
        } else {
          return httpClient.post('/api/subscriber', {
            ...member,
            organizationId,
          });
        }
      });

      await Promise.all(updateTasks);
      fetchMembers();

      notifications.show({
        title: 'Success',
        message: 'Members updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to submit members',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (organizationId) {
      fetchMembers();
    }
  }, [organizationId]);

  return (
    <Container>
      <Grid>
        <Grid.Col span={4}>
          <Paper withBorder shadow="xs" p="md">
            <Title order={4}>Organization Members</Title>
            <Table highlightOnHover>
              <tbody>
                {fields.map((member, index) => (
                  <React.Fragment key={member.id}>
                    <tr
                      onClick={() => {
                        setSelectedMemberIndex(index);
                        setIsAddingNewMember(false);
                        setIsEditing(false);
                      }}
                      className={`member-row ${index === selectedMemberIndex ? 'selected-member' : ''}`}
                    >
                      <td>
                        {member.firstname} {member.lastname}
                      </td>
                    </tr>
                    {member.familyMembers &&
                      member.familyMembers.map((familyMember) => (
                        <tr
                          key={familyMember.id}
                          className="family-member-row"
                          onClick={() => {
                            setSelectedMemberIndex(index);
                            setIsAddingNewMember(false);
                            setIsEditing(false);
                          }}
                        >
                          <td>
                            {familyMember.firstname} {familyMember.lastname}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
                <tr
                  onClick={handleAddMember}
                  className="member-row add-member-row"
                >
                  <td>
                    <Plus size={16} color="#238be6" /> Add Member
                  </td>
                </tr>
              </tbody>
            </Table>
          </Paper>
        </Grid.Col>
        <Grid.Col span={8}>
          {selectedMemberIndex !== null && (
            <form
              onSubmit={
                isAddingNewMember
                  ? handleSubmit(handleNewMemberSubmit)
                  : handleSubmit(onSubmit)
              }
            >
              <Paper withBorder shadow="xs" p="md">
                <Grid justify="space-between" align="center">
                  <Grid.Col span={7}>
                    <Title order={3}>
                      {fields[selectedMemberIndex].firstname}{' '}
                      {fields[selectedMemberIndex].lastname}
                    </Title>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    {!isAddingNewMember &&
                      fields[selectedMemberIndex].enrollmentstatus ===
                        'Complete' && (
                        <Button variant="outline" color="green" ml="sm">
                          Approve Family
                        </Button>
                      )}
                  </Grid.Col>
                  <Grid.Col
                    span={3}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <ActionIcon
                      onClick={() => setIsEditing((prev) => !prev)}
                      variant="outline"
                      color="blue"
                      ml="sm"
                    >
                      {isEditing ? <Lock size={16} /> : <Pencil size={16} />}
                    </ActionIcon>
                  </Grid.Col>
                </Grid>
                <Paper withBorder shadow="xs" p="md" mt="md">
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        {...register(
                          `members.${selectedMemberIndex}.firstname`,
                          { required: true },
                        )}
                        disabled={!isEditing && !isAddingNewMember}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        {...register(
                          `members.${selectedMemberIndex}.lastname`,
                          { required: true },
                        )}
                        disabled={!isEditing && !isAddingNewMember}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`members.${selectedMemberIndex}.birthdate`}
                        control={control}
                        render={({ field }) => (
                          <DateInput
                            label="Date of Birth"
                            value={
                              field.value && field.value !== ''
                                ? new Date(field.value + 'T00:00:00')
                                : null
                            } // Handle empty strings
                            onChange={(date) =>
                              field.onChange(
                                date ? date.toISOString().slice(0, 10) : null,
                              )
                            } // Convert empty string to null
                            disabled={!isEditing && !isAddingNewMember}
                            clearable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Enrollment Status"
                        {...register(
                          `members.${selectedMemberIndex}.enrollmentstatus`,
                          { required: true },
                        )}
                        disabled={!isEditing && !isAddingNewMember}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Email"
                        {...register(`members.${selectedMemberIndex}.email`, {
                          required: true,
                        })}
                        disabled={!isEditing && !isAddingNewMember}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={2.4}>
                      {!isAddingNewMember &&
                        fields[selectedMemberIndex].enrollmentstatus ===
                          'Complete' && (
                          <Button variant="outline" color="green">
                            Approve
                          </Button>
                        )}
                    </Grid.Col>
                    <Grid.Col span={2}>
                      {!isAddingNewMember &&
                        fields[selectedMemberIndex].enrollmentstatus ===
                          'Complete' && (
                          <Button variant="outline" color="red">
                            Deny
                          </Button>
                        )}
                    </Grid.Col>
                    <Grid.Col span={2}>
                      {isEditing && <Button type="submit">Submit</Button>}
                    </Grid.Col>
                  </Grid>
                </Paper>
                {fields[selectedMemberIndex].familyMembers &&
                  fields[selectedMemberIndex].familyMembers.map(
                    (familyMember, familyIndex) => (
                      <Paper
                        withBorder
                        shadow="xs"
                        p="md"
                        mt="md"
                        key={familyMember.id}
                      >
                        <Grid justify="space-between" align="center">
                          <Grid.Col span={11}>
                            <Title order={4}>
                              {familyMember.firstname} {familyMember.lastname} (
                              {familyMember.subscriberrelationship})
                            </Title>
                          </Grid.Col>
                        </Grid>
                        <Paper withBorder shadow="xs" p="md" mt="md">
                          <Grid>
                            <Grid.Col span={6}>
                              <TextInput
                                label="First Name"
                                {...register(
                                  `members.${selectedMemberIndex}.familyMembers.${familyIndex}.firstname`,
                                  {
                                    required: true,
                                  },
                                )}
                                disabled={!isEditing && !isAddingNewMember}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Last Name"
                                {...register(
                                  `members.${selectedMemberIndex}.familyMembers.${familyIndex}.lastname`,
                                  {
                                    required: true,
                                  },
                                )}
                                disabled={!isEditing && !isAddingNewMember}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`members.${selectedMemberIndex}.familyMembers.${familyIndex}.birthdate`}
                                control={control}
                                render={({ field }) => (
                                  <DateInput
                                    label="Date of Birth"
                                    value={
                                      field.value && field.value !== ''
                                        ? new Date(field.value + 'T00:00:00')
                                        : null
                                    } // Handle empty strings
                                    onChange={(date) =>
                                      field.onChange(
                                        date
                                          ? date.toISOString().slice(0, 10)
                                          : null,
                                      )
                                    } // Convert empty string to null
                                    disabled={!isEditing && !isAddingNewMember}
                                    clearable
                                  />
                                )}
                              />
                            </Grid.Col>
                          </Grid>
                          <Grid>
                            <Grid.Col span={2.4}>
                              <Button variant="outline" color="green">
                                Approve
                              </Button>
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <Button variant="outline" color="red">
                                Deny
                              </Button>
                            </Grid.Col>
                          </Grid>
                        </Paper>
                      </Paper>
                    ),
                  )}
              </Paper>
            </form>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default EmployeeManagementTab;
