import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { jwtDecode } from 'jwt-decode';

interface ProtectedRouteProps {
  requiredPrivilege: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredPrivilege,
}) => {
  const auth = useAuth();
  const token = auth.getToken();

  // Redirect to /login if no token is found
  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    console.log('decodedToken', decodedToken);

    if (
      decodedToken.privileges &&
      decodedToken.privileges.some((p) => p.type === 'admin')
    ) {
      return <Outlet />;
    } else {
      return <Navigate to="/no-access" />;
    }
  } catch (e) {
    console.error('Token decoding failed:', e);
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
