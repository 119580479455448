import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { JwtToken } from '../../types/common.types';
import { doesNotMatch } from 'assert';

export const useAuth = () => {
  const [organizationId, setOrganizationId] = useState<number>(0);
  const [subscriberMemberId, setSubscriberMemberId] = useState<number>(0);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode<JwtToken>(token);
        console.log('decoded token', decodedToken);
        if (decodedToken?.insurance?.organizationId) {
          setOrganizationId(decodedToken.insurance.organizationId);
        }
        setSubscriberMemberId(findInsuranceMemberServiceId(decodedToken));
      } catch (error) {
        console.error('Failed to decode JWT:', error);
      }
    }
  }, []);

  return { organizationId, subscriberMemberId };
};

function findInsuranceMemberServiceId(authData) {
  if (authData.privileges && Array.isArray(authData.privileges)) {
    const insuranceMemberPrivilege = authData.privileges.find(
      (privilege) => privilege.type === 'insurance_member',
    );
    return insuranceMemberPrivilege ? insuranceMemberPrivilege.serviceid : null;
  }
  return null;
}
