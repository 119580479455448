import React, { useState } from 'react';
import { Modal, Button, Checkbox, Text, Group } from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useInsuranceMemberContext } from './InsuranceMemberContext';

interface PrivacyPolicyModalProps {
  opened: boolean;
  onClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  opened,
  onClose,
}) => {
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();
  const { member } = useInsuranceMemberContext();

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.currentTarget.checked);
  };

  const completeRegistration = async () => {
    setLoading(true);
    try {
      await httpClient.put(
        `/api/subscriber/${member.id}/complete-registration`,
      );
      onClose(); // Close the modal after successful registration
    } catch (error) {
      console.error('Error completing registration:', error);
      // Handle the error appropriately (e.g., show a notification)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Privacy Policy" centered>
      <Text>
        Here is some placeholder text for the privacy policy. Please review it
        carefully.
      </Text>

      <Group mt="md">
        <Checkbox
          label="I agree to the privacy policy"
          checked={agreed}
          onChange={handleAgreeChange}
        />
        <Button disabled={!agreed || loading} onClick={completeRegistration}>
          {loading ? 'Completing...' : 'Complete Registration'}
        </Button>
      </Group>
    </Modal>
  );
};

export default PrivacyPolicyModal;
