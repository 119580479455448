import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Tabs, Grid } from '@mantine/core';
import EmployeeManagementTab from './EmployeeManagementTab';
import PracticeInfoTab from './PracticeInfoTab';
import PaymentInformationTab from './PaymentInformationTab';
import HomeIcon from '../HomeIcon';
import LogoutIcon from '../LogoutIcon';
import DarkModeToggle from '../DarkModeToggle';
import { useOrganizationContext } from './OrganizationContext';
import useHttpClient from '../hooks/useHttpClient';
import { Organization } from '../../types/organization.types';

const InsuranceAdminPortal: React.FC = () => {
  const { organization } = useOrganizationContext();

  console.log('organization', organization);
  
  return (
    <>
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Tabs defaultValue="employees">
        <Tabs.List>
          <Tabs.Tab value="employees">Manage Employees</Tabs.Tab>
          <Tabs.Tab value="practice-info">Practice Information</Tabs.Tab>
          <Tabs.Tab value="payment-information">Payment Information</Tabs.Tab>
          <Tabs.Tab value="financial-statements">Financial Statements</Tabs.Tab>
          <Tabs.Tab value="settings">Settings</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="employees">
          <EmployeeManagementTab />
        </Tabs.Panel>
        <Tabs.Panel value="practice-info">
          <PracticeInfoTab />
        </Tabs.Panel>
        <Tabs.Panel value="payment-information">
          <PaymentInformationTab />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default InsuranceAdminPortal;
