import { useEffect } from 'react';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const useHttpClient = (): AxiosInstance => {
  // Create an instance of Axios
  const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Interceptor to add token to requests
  useEffect(() => {
    const requestInterceptor = httpClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
      },
    );

    // Interceptor to handle responses
    const responseInterceptor = httpClient.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => response,
      (error: AxiosError): Promise<AxiosError> => {
        // Optionally handle certain errors globally
        if (error.response?.status === 401) {
          // Handle unauthorized error, e.g., redirect to login
          console.log('Unauthorized, redirecting to login...');
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
        return Promise.reject(error);
      },
    );

    // Clean up interceptors on unmount
    return () => {
      httpClient.interceptors.request.eject(requestInterceptor);
      httpClient.interceptors.response.eject(responseInterceptor);
    };
  }, [httpClient]);

  return httpClient;
};

export default useHttpClient;
