import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';

const EmploymentForm: React.FC = () => {
  const { memberId } = useMemberContext();
  const { control, register, handleSubmit, watch, reset } = useForm({});
  const httpClient = useHttpClient();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employments',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();

  const fetchEmployments = async () => {
    try {
      const response = await httpClient.get(`/api/employment/${memberId}`);
      if (!response.status.toString().startsWith('2')) {
        throw new Error('Failed to fetch employments');
      }
      const fetchedEmployments = await response.data;

      const sortedEmployments = fetchedEmployments.sort((a: any, b: any) => {
        if (a.current === 'Y' && b.current === 'Y') {
          return (
            new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
          );
        } else if (a.current === 'Y') {
          return -1;
        } else if (b.current === 'Y') {
          return 1;
        } else if (!a.enddate) {
          return -1;
        } else if (!b.enddate) {
          return 1;
        } else {
          const endDateComparison =
            new Date(b.enddate).getTime() - new Date(a.enddate).getTime();
          if (endDateComparison !== 0) return endDateComparison;
          return a.id - b.id;
        }
      });

      reset({ employments: sortedEmployments });
      setInitialData(sortedEmployments);
    } catch (error) {
      console.error('Error fetching employments:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch employments',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    fetchEmployments();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);

    const formattedData = data.employments.map((item: any) => ({
      ...item,
      memberid: memberId,
    }));

    const createEmployment = async (employment: any) => {
      const response = await fetch('/api/employment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(employment),
      });

      return response.json();
    };

    const updateEmployment = async (employment: any) => {
      const response = await fetch(`/api/employment/${employment.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(employment),
      });
      return response.json();
    };

    const deleteEmployment = async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/employment/${id}`,
        {
          method: 'DELETE',
        },
      );
      console.log('delete response', response);
    };

    const initialIds = new Set<number>(initialData?.map((e: any) => e.id));
    const currentIds = new Set<number>(formattedData.map((e: any) => e.id));
    const deletedIds = [...initialIds].filter((id): id is number => !currentIds.has(id));

    const createTasks = formattedData
      .filter((e: any) => !e.id || e.id === 0)
      .map((e: any) => createEmployment(e));
    const updateTasks = formattedData
      .filter((e: any) => e.id && e.id > 0 && initialIds.has(e.id))
      .map((e: any) => updateEmployment(e));
    const deleteTasks = deletedIds.map((id) => deleteEmployment(id));

    try {
      const results = await Promise.all([...createTasks, ...updateTasks, ...deleteTasks]);
      console.log('Results:', results);
      fetchEmployments();

      notifications.show({
        title: 'Success',
        message: 'Employments updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting employment form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit employment',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => {
          const currentField = watch(`employments.${index}.current`);
          return (
            <Paper key={item.id} shadow="xs" p="md" withBorder>
              <Group>
                <h3>Employment Location {index + 1}</h3>
                <Button onClick={() => toggleCollapse(index)} size="xs">
                  {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                </Button>
                <Button color="red" onClick={() => remove(index)} size="xs">
                  Remove
                </Button>
              </Group>
              <Collapse in={!collapsedIndices[index]}>
                <TextInput
                  label="Practice/Employer"
                  {...register(`employments.${index}.employer`)}
                />
                <TextInput
                  label="Department/Specialty"
                  {...register(`employments.${index}.departmentspecialty`)}
                />
                <TextInput
                  label="Street 1"
                  {...register(`employments.${index}.street1`)}
                />
                <TextInput
                  label="Street 2"
                  {...register(`employments.${index}.street2`)}
                />
                <Grid>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.country`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Country"
                          data={[{ value: 'US', label: 'United States' }]}
                          clearable
                          searchable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="City"
                      {...register(`employments.${index}.city`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.state`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="State"
                          data={STATES_ARRAY.map((state) => ({
                            value: state,
                            label: state,
                          }))}
                          clearable
                          searchable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="ZIP"
                      {...register(`employments.${index}.zip`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.phone`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Phone">
                          <Input
                            component={IMaskInput}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                            {...field}
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.fax`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Fax">
                          <Input
                            component={IMaskInput}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                            {...field}
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                </Grid>
                <Controller
                  name={`employments.${index}.current`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Is this your current employer?"
                      data={[
                        { value: 'Y', label: 'Yes' },
                        { value: 'N', label: 'No' },
                      ]}
                      clearable
                    />
                  )}
                />
                <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`employments.${index}.startdate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          label="Start Date"
                          placeholder="Pick a date"
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>

                {currentField !== 'Y' && (
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.enddate`}
                      control={control}
                      render={({ field }) => {
                        const parsedDate = field.value && field.value.length === 10
                          ? new Date(field.value + 'T00:00:00')
                          : new Date(field.value);

                        return (
                          <DateInput
                            {...field}
                            value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                            onChange={(date) =>
                              field.onChange(date ? date.toISOString().slice(0, 10) : null)
                            }
                            label="End Date"
                            placeholder="Pick a date"
                            clearable
                          />
                        );
                      }}
                    />
                  </Grid.Col>
                )}
                </Grid>
              </Collapse>
            </Paper>
          );
        })}

        <Group mt="md">
          <Button
            onClick={() =>
              append({
                employer: '',
                departmentspecialty: '',
                street1: '',
                street2: '',
                country: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                fax: '',
                startdate: null,
                enddate: null,
                current: 'N',
              })
            }
          >
            Add Employment Location
          </Button>
        </Group>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default EmploymentForm;
