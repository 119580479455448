import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Input,
  Table,
  ActionIcon,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { Plus, Pencil, Lock } from 'tabler-icons-react';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import useHttpClient from '../hooks/useHttpClient';
import './css/FamilyManagementTab.css';
import {
  SubscriberMember,
  OrganizationMember,
} from '../../types/subscribermember.types';

interface FormValues {
  members: OrganizationMember[];
}

interface FamilyManagementTabProps {
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

const FamilyManagementTab: React.FC<FamilyManagementTabProps> = ({
  onFieldsCompleteChange,
}) => {
  const { subscriberMemberId, member } = useInsuranceMemberContext();
  console.log('familyh managment tab', member);
  const { control, register, handleSubmit, reset, watch } =
    useForm<FormValues>();
  const { fields, append } = useFieldArray({
    control,
    name: 'members',
  });
  const [selectedMemberIndex, setSelectedMemberIndex] = useState<number | null>(
    null,
  );
  const [isEditing, setIsEditing] = useState(false);
  const httpClient = useHttpClient();

  const fetchMembers = async () => {
    try {
      const response = await httpClient.get(
        `/api/subscriber/${subscriberMemberId}/family`,
      );
      const fetchedMembers: OrganizationMember[] = response.data || [];

      reset({ members: fetchedMembers });
      checkFieldsComplete(fetchedMembers);
    } catch (error) {
      console.error('Error fetching members:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch family members',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const handleAddFamilyMember = () => {
    if (!member?.organizationid) {
      notifications.show({
        title: 'Error',
        message: 'Organization ID is missing.',
        color: 'red',
        position: 'bottom-right',
      });
      return;
    }

    append({
      firstname: '',
      lastname: '',
      birthdate: null,
      ssn: '',
      organizationid: member.organizationid, // Use the organization ID from the member context
      primarysubscriber: subscriberMemberId,
    });
    setSelectedMemberIndex(fields.length);
    setIsEditing(true);
  };

  const onSubmit = async (data: FormValues) => {
    const membersToSubmit = data.members.map((member) => {
      const { familyMembers, ...memberWithoutFamily } = member;

      const processedMember = {
        ...memberWithoutFamily,
        birthdate:
          memberWithoutFamily.birthdate === ''
            ? null
            : memberWithoutFamily.birthdate,
        familyMembers: member.familyMembers?.map((familyMember) => ({
          ...familyMember,
          birthdate:
            familyMember.birthdate === '' ? null : familyMember.birthdate,
        })),
      };

      return processedMember;
    });

    try {
      const updateTasks = membersToSubmit.map((member) => {
        if (member.id) {
          return httpClient.put(`/api/subscriber/${member.id}`, member);
        } else {
          return httpClient.post(`/api/subscriber/`, { ...member });
        }
      });

      await Promise.all(updateTasks);
      fetchMembers();

      notifications.show({
        title: 'Success',
        message: 'Members updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to submit members',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const checkFieldsComplete = (members: OrganizationMember[]) => {
    const requiredFields = ['firstname', 'lastname', 'birthdate', 'ssn'];

    const allComplete = members.every((member) =>
      requiredFields.every(
        (field) =>
          (member[field as keyof OrganizationMember] &&
            member[field as keyof OrganizationMember] !== '') ||
          false, // This ensures that the field is treated as incomplete if it doesn't exist
      ),
    );

    if (onFieldsCompleteChange) {
      onFieldsCompleteChange(allComplete);
    }
  };

  useEffect(() => {
    if (subscriberMemberId) {
      fetchMembers();
    }
  }, [subscriberMemberId]);

  useEffect(() => {
    const subscription = watch((value) => {
      const members = value.members as OrganizationMember[]; // Cast to FamilyMember[]
      checkFieldsComplete(members);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Container>
      <Grid>
        <Grid.Col span={4}>
          <Paper withBorder shadow="xs" p="md">
            <Title order={4}>Family Members</Title>
            <Table highlightOnHover>
              <tbody>
                {fields.map((member, index) => (
                  <tr
                    key={member.id || index}
                    onClick={() => {
                      setSelectedMemberIndex(index);
                      setIsEditing(false);
                    }}
                    className={`family-member-row ${index === selectedMemberIndex ? 'selected-member' : ''}`}
                  >
                    <td>
                      {member.firstname} {member.lastname}
                    </td>
                  </tr>
                ))}
                <tr
                  onClick={handleAddFamilyMember}
                  className="family-member-row add-member-row"
                >
                  <td>
                    <Plus size={16} color="#238be6" /> Add a Family Member
                  </td>
                </tr>
              </tbody>
            </Table>
          </Paper>
        </Grid.Col>
        <Grid.Col span={8}>
          {selectedMemberIndex !== null && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper withBorder shadow="xs" p="md">
                <Grid justify="space-between" align="center">
                  <Grid.Col span={11}>
                    <Title order={3}>
                      {fields[selectedMemberIndex].firstname}{' '}
                      {fields[selectedMemberIndex].lastname}
                    </Title>
                  </Grid.Col>
                  <Grid.Col
                    span={1}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <ActionIcon
                      onClick={() => setIsEditing((prev) => !prev)}
                      variant="outline"
                      color="blue"
                    >
                      {isEditing ? <Lock size={16} /> : <Pencil size={16} />}
                    </ActionIcon>
                  </Grid.Col>
                </Grid>
                <Paper withBorder shadow="xs" p="md" mt="md">
                  <Title order={4}>Basic Information</Title>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        {...register(
                          `members.${selectedMemberIndex}.firstname`,
                          { required: true },
                        )}
                        disabled={!isEditing}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        {...register(
                          `members.${selectedMemberIndex}.lastname`,
                          { required: true },
                        )}
                        disabled={!isEditing}
                      />
                    </Grid.Col>
                  </Grid>
                  <Controller
                    name={`members.${selectedMemberIndex}.birthdate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate =
                        field.value && field.value.length === 10
                          ? new Date(field.value + 'T00:00:00') // Append time for YYYY-MM-DD format
                          : new Date(field.value); // For full ISO strings, no need to append

                      return (
                        <DateInput
                          label="Date of Birth"
                          value={
                            parsedDate && !isNaN(parsedDate.getTime())
                              ? parsedDate
                              : null
                          }
                          onChange={(date) =>
                            field.onChange(
                              date ? date.toISOString().slice(0, 10) : null,
                            )
                          }
                          disabled={!isEditing}
                          clearable
                        />
                      );
                    }}
                  />
                </Paper>
                <Paper withBorder shadow="xs" p="md" mt="md">
                  <Title order={4}>Complex Information</Title>
                  <Controller
                    name={`members.${selectedMemberIndex}.ssn`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="SSN">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="000-00-0000"
                          placeholder="XXX-XX-XXXX"
                          disabled={!isEditing}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Paper>
                {isEditing && (
                  <Button type="submit" mt="md">
                    Submit
                  </Button>
                )}
              </Paper>
            </form>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default FamilyManagementTab;
