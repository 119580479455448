import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Title,
  Text,
  PasswordInput,
  Textarea,
  Checkbox,
} from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import PasswordStrengthBar from 'react-password-strength-bar';
import { STATES_ARRAY } from '../../types/members.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';

interface PhysicianFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const PhysicianForm: React.FC<PhysicianFormProps> = ({
  initialData,
  onFormSubmit,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialData,
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password'); // Watch password for confirmation validation

  const initialPriorities = [
    { id: '1', label: 'Affordable Costs' },
    {
      id: '2',
      label: 'Ease of use and navigation (doctor and hospital choice)',
    },
    { id: '3', label: 'Billing clarity and transparency' },
  ];

  const [priorities, setPriorities] = useState(initialPriorities);

  const onDragEnd = (result) => {
    if (!result.destination) return; // If the item is dropped outside the list, do nothing
    const updatedPriorities = Array.from(priorities);
    const [reorderedItem] = updatedPriorities.splice(result.source.index, 1);
    updatedPriorities.splice(result.destination.index, 0, reorderedItem);
    setPriorities(updatedPriorities);
  };

  const onSubmit = async (data: any) => {
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-right',
      });
      return;
    }

    // Prepare affiliations array and remove "Other" while adding "otherIpaName"
    const affiliations = data.affiliations?.filter((item) => item !== 'Other');
    if (data.otherIpaName) {
      affiliations.push(data.otherIpaName); // Add the Other IPA name directly to the affiliations list
    }
    delete data.otherIpaName; // Remove otherIpaName from data to prevent it from being sent

    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
      type: 'independent',
      affiliations, // Use the modified affiliations list
      priorities: priorities.map((priority) => priority.label), // Submit priorities in the ranked order
    };
    delete submissionData.otherIpaName;

    delete submissionData.passwordConfirmation; // Remove password confirmation field from submission

    try {
      const response = await httpClient.post(
        '/api/subscriber/verify',
        submissionData,
      );

      const addUserResult = response.data.addUserResult;

      if (onFormSubmit) {
        onFormSubmit(response.data);
      }

      notifications.show({
        title: 'Success',
        message: 'Physician registered successfully',
        color: 'green',
        position: 'bottom-right',
      });

      // Navigate to confirmation page
      navigate('/insurance/register/confirmation', {
        state: { addUserResult }, // Pass the result as state
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit physician form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Personal Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Medical License Number"
                required
                {...register('licensenumber', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="licensestate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Medical License State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="NPI"
                required
                maxLength={10}
                {...register('npi', { required: true })}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Login Information</Title>
          <Text mt="xs" size="sm" color="dimmed">
            This information will be used to create your account. Please choose
            a password of at least "Good" strength.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <PasswordInput
                label="Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                maxLength={20}
                {...register('password', { required: true })}
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score) => setPasswordStrengthScore(score)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Confirm Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                maxLength={20}
                {...register('passwordConfirmation', {
                  required: true,
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                })}
                error={
                  errors.passwordConfirmation ? 'Passwords do not match' : null
                }
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Additional Information</Title>

          {/* affiliations Status */}
          <Text mt="xs" size="sm" fw={500}>
            {' '}
            {/* Set weight to match other labels */}
            Are You Currently a Member?
          </Text>
          <Text mt="xs" size="sm" color="dimmed">
            {' '}
            {/* Subtitle in dimmed text */}
            Check all that apply
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <Controller
                name="affiliations"
                control={control}
                render={({ field }) => (
                  <Checkbox.Group {...field}>
                    <Checkbox value="WeCare IPA" label="WeCare IPA" />
                    <Checkbox
                      value="MSSNY"
                      label="Medical Society of the State of New York"
                    />
                    <Checkbox value="Other" label="Other IPA" />
                    <TextInput
                      placeholder="Please type the name of the other IPA"
                      {...register('otherIpaName')}
                      disabled={watch('affiliations')?.indexOf('Other') === -1} // Enable when "Other" is selected
                    />
                  </Checkbox.Group>
                )}
              />
            </Grid.Col>
          </Grid>

          {/* Health Insurance Priorities */}
          <Text mt="xs" size="sm" fw={500}>
            {' '}
            {/* Set weight to match other labels */}
            What is the Most Important to You About Your Health Insurance?
          </Text>
          <Text mt="xs" size="sm" color="dimmed">
            {' '}
            {/* Subtitle in dimmed text */}
            Drag and drop to rank order your priorities
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="priorities">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {priorities.map((priority, index) => (
                        <Draggable
                          key={priority.id}
                          draggableId={priority.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                padding: '8px',
                                margin: '4px 0',
                                backgroundColor: '#f1f1f1',
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'grab', // Set grab cursor by default
                                userSelect: 'none', // Prevent text selection
                                ...provided.draggableProps.style,
                              }}
                            >
                              <span>{priority.label}</span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Questions or Comments</Title>
          <Text mt="xs" size="sm" color="dimmed">
            If you have any questions, comments, concerns about your
            registration or Ryze Health, or simply want to share your thoughts,
            feel free to leave us a message below.
          </Text>
          <Textarea
            placeholder="Please write any questions or comments you have here..."
            minRows={4}
            {...register('message')}
          />
        </Paper>

        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default PhysicianForm;
