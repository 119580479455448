import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text, Button, Grid } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
interface Member {
  id: string;
  firstname: string;
  lastname: string;
  licensenumber: string;
  licensestate: string;
  npi: string;
  npimatch: string;
  affiliations: string[];
  message?: string;
}

const InsuranceMemberApprovalPage: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const httpClient = useHttpClient();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await httpClient.get('/api/subscriber/approve'); // Replacing fetch with httpClient.get

        if (response.status === 200) {
          const data = response.data;
          setMembers(data);
        } else {
          notifications.show({
            title: 'Error',
            message: 'Failed to fetch members for approval.',
            color: 'red',
            position: 'bottom-right',
          });
        }
      } catch (error) {
        console.error('Error fetching members:', error);
        notifications.show({
          title: 'Error',
          message: 'An error occurred while fetching members.',
          color: 'red',
          position: 'bottom-right',
        });
      }
    };

    fetchMembers();
  }, []);

  // Function to handle approval or denial
  const handleAction = async (memberId: string, approved: boolean) => {
    try {
      const response = await httpClient.put('/api/user/approve', {
        id: memberId,
        approved,
        type: 'insurance_member',
      });

      if (response.status === 200) {
        notifications.show({
          title: approved ? 'Member Approved' : 'Member Denied',
          message: approved
            ? 'Member has been approved successfully.'
            : 'Member has been denied.',
          color: approved ? 'green' : 'red',
          position: 'bottom-right',
        });
        // Remove the member from the list after action
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== memberId),
        );
      } else {
        throw new Error('Action failed');
      }
    } catch (error) {
      console.error('Error performing action:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update member status.',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <Title order={2} mb="md" ta="center">
        Members Pending Approval
      </Title>
      {members.length === 0 ? (
        <Text ta="center">No members requiring approval.</Text>
      ) : (
        members.map((member, index) => (
          <Paper key={index} withBorder shadow="sm" p="md" mb="md">
            <Grid>
              <Grid.Col span={6}>
                <Text>
                  <strong>Name:</strong> {member.firstname} {member.lastname}
                </Text>
                <Text>
                  <strong>Medical License:</strong> {member.licensenumber} (
                  {member.licensestate})
                </Text>
                <Text>
                  <strong>Affiliations:</strong>{' '}
                  {member.affiliations.join(', ')}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text>
                  <strong>NPI:</strong> {member.npi}
                </Text>
                <Text>
                  <strong>NPI Match:</strong>{' '}
                  <span
                    style={{
                      color: member.npimatch === 'match' ? 'green' : 'red', // Color only the match result
                    }}
                  >
                    {member.npimatch}
                  </span>
                </Text>
              </Grid.Col>
            </Grid>
            {member.message && (
              <Text mt="md">
                <strong>Message:</strong> {member.message}
              </Text>
            )}
            <Grid justify="space-between" mt="md">
              <Grid.Col span={6}>
                <Button
                  color="green"
                  fullWidth
                  onClick={() => handleAction(member.id, true)}
                  style={{ marginRight: 10 }}
                >
                  Approve
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  color="red"
                  variant="outline"
                  fullWidth
                  onClick={() => handleAction(member.id, false)}
                >
                  Deny
                </Button>
              </Grid.Col>
            </Grid>
          </Paper>
        ))
      )}
    </Container>
  );
};

export default InsuranceMemberApprovalPage;
