import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Title,
  Grid,
  Card,
  Text,
  Anchor,
} from '@mantine/core';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';

const CheckoutForm: React.FC<{
  clientSecret: string;
  paymentCustomerId: number;
}> = ({ clientSecret, paymentCustomerId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const httpClient = useHttpClient();
  const { register, handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organizationId } = useOrganizationContext();

  const onSubmit = async (data: any) => {
    if (!stripe || !elements) {
      return;
    }
    setIsSubmitting(true);

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.HOST_APP_URL}/insurance/admin`,
      },
      redirect: 'if_required',
    });
    console.log('result from submit', result);
    if (result.error) {
      notifications.show({
        title: 'Error',
        message: result.error.message || 'Failed to set up payment method',
        color: 'red',
        position: 'bottom-right',
      });
    } else {
      // After successfully setting up the payment method, create a subscription
      const paymentMethodId = result.setupIntent.payment_method;
      const subscriptionResponse = await httpClient.post(
        '/api/stripe/create-subscription',
        JSON.stringify({ paymentMethodId, paymentCustomerId, organizationId }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      console.log('subscription response', subscriptionResponse);
      const subscriptionData = await subscriptionResponse.data;

      if (subscriptionData.error) {
        notifications.show({
          title: 'Error',
          message: subscriptionData.error.message,
          color: 'red',
          position: 'bottom-right',
        });
      } else {
        notifications.show({
          title: 'Success',
          message: 'Subscription created successfully',
          color: 'green',
          position: 'bottom-right',
        });
      }
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PaymentElement />
      <Button type="submit" disabled={isSubmitting} mt="md">
        Submit
      </Button>
    </form>
  );
};

export default CheckoutForm;
