import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Tabs, Grid } from '@mantine/core';
import MemberForm from './MemberForm';
import HospitalForm from './HospitalForm';
import PracticeForm from './PracticeForm';
import CredentialingContactForm from './CredentialingContactForm';
import SpecialtyForm from './SpecialtyForm';
import LiabilityForm from './LiabilityForm';
import EmploymentForm from './EmploymentForm';
import ReferenceForm from './ReferenceForm';
import CertificationForm from './CertificationForm';
import EducationTrainingForm from './EducationTrainingForm';
import DisclosureForm from './DisclosureForm';
import ProfessionalIDsForm from './ProfessionalIDsForm';
import { useMemberContext, MemberProvider } from './MemberContext';
import HomeIcon from './HomeIcon';
import LogoutIcon from './LogoutIcon';
import DarkModeToggle from './DarkModeToggle';
import { Member } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';

const MemberPortal: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isNewMember, memberId, setMemberId, setIsNewMember } =
    useMemberContext();
  const [member, setMember] = useState<Member>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('members');
  const navigate = useNavigate();
  const httpClient = useHttpClient();
  const fetchMember = async (id: number) => {
    try {
      const response = await httpClient.get(`/api/members/${id}`);
      const data = await response.data;
      setMember(data);
      setMemberId(id);
    } catch (error) {
      console.error('Error fetching member', error);
    }
  };

  useEffect(() => {
    if (!isNewMember && id) {
      fetchMember(parseInt(id));
    }
  }, [id, isNewMember]);

  const handleMemberCreated = (newMemberId: number) => {
    setIsNewMember(false);
    setMemberId(newMemberId);
  };

  const handleTabChange = useCallback(
    (value: string) => {
      if (hasUnsavedChanges && activeTab === 'members') {
        const confirmLeave = window.confirm(
          'You have unsaved changes. Do you really want to leave?',
        );
        if (!confirmLeave) {
          return;
        }
      }
      setActiveTab(value);
    },
    [hasUnsavedChanges, activeTab],
  );

  if (!isNewMember && memberId === 0) {
    return <Navigate to="/error" />;
  }

  return (
    <>
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tabs.List>
          <Tabs.Tab value="members">Personal Info</Tabs.Tab>
          <Tabs.Tab value="specialties">Specialties</Tabs.Tab>
          <Tabs.Tab value="practices">Practice Locations</Tabs.Tab>
          <Tabs.Tab value="hospitals">Hospitals</Tabs.Tab>
          <Tabs.Tab value="professionalIds">Professional IDs</Tabs.Tab>
          <Tabs.Tab value="certifications">Certifications</Tabs.Tab>
          <Tabs.Tab value="education">
            Education & Professional Training
          </Tabs.Tab>
          <Tabs.Tab value="liabilities">Liability Coverage</Tabs.Tab>
          <Tabs.Tab value="credentialingContacts">
            Credentialing Contacts
          </Tabs.Tab>
          <Tabs.Tab value="employment">Employment History</Tabs.Tab>
          <Tabs.Tab value="references">Professional References</Tabs.Tab>
          <Tabs.Tab value="disclosures">Disclosures</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="members">
          {(isNewMember || member) && (
            <MemberForm
              member={member}
              setMember={setMember}
              memberId={memberId}
              onMemberCreated={handleMemberCreated}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="hospitals">
          <HospitalForm />
        </Tabs.Panel>
        <Tabs.Panel value="practices">
          <PracticeForm />
        </Tabs.Panel>
        <Tabs.Panel value="credentialingContacts">
          <CredentialingContactForm />
        </Tabs.Panel>
        <Tabs.Panel value="specialties">
          <SpecialtyForm />
        </Tabs.Panel>
        <Tabs.Panel value="liabilities">
          <LiabilityForm />
        </Tabs.Panel>
        <Tabs.Panel value="employment">
          <EmploymentForm />
        </Tabs.Panel>
        <Tabs.Panel value="references">
          <ReferenceForm />
        </Tabs.Panel>
        <Tabs.Panel value="certifications">
          <CertificationForm />
        </Tabs.Panel>
        <Tabs.Panel value="professionalIds">
          <ProfessionalIDsForm />
        </Tabs.Panel>
        <Tabs.Panel value="education">
          <EducationTrainingForm />
        </Tabs.Panel>
        <Tabs.Panel value="disclosures">
          <DisclosureForm />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default MemberPortal;
