import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Input,
  Select,
  ActionIcon,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { STATES_ARRAY } from '../../types/members.types';
import { Pencil, Lock } from 'tabler-icons-react';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';

interface PracticeInfoTabProps {
  onFormSubmit?: (data: any) => void;
}

const PracticeInfoTab: React.FC<PracticeInfoTabProps> = ({ onFormSubmit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { control, register, handleSubmit, reset } = useForm();
  const [initialData, setInitialData] = useState<any>(null);
  const { organizationId } = useOrganizationContext();
  const httpClient = useHttpClient();

  const fetchPracticeInfo = async () => {
    try {
      const response = await httpClient.get(`/api/organization/${organizationId}`);
      setInitialData(response.data);
      reset(response.data); // Initialize form with fetched data
    } catch (error) {
      console.error('Error fetching practice info:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch practice info',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (organizationId) {
      fetchPracticeInfo();
    }
  }, [organizationId]);

  const onSubmit = async (data: any) => {
    try {
      await httpClient.put(`/api/organization/${organizationId}`, data);

      if (onFormSubmit) {
        onFormSubmit(data);
      }

      notifications.show({
        title: 'Success',
        message: 'Practice info updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update practice info',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <Title order={3}>Practice Info</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Grid justify="space-between" align="center">
            <Grid.Col span={10}>
              <Title order={4}>Organization Details</Title>
            </Grid.Col>
            <Grid.Col span={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ActionIcon
                onClick={() => setIsEditing((prev) => !prev)}
                variant="outline"
                color="blue"
              >
                {isEditing ? <Lock size={16} /> : <Pencil size={16} />}
              </ActionIcon>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Business Name"
                required
                {...register('name', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Practice Website"
                {...register('practicewebsite')}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Tax Identification Number"
                required
                {...register('tin', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={4}>Address Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Street 1"
                required
                {...register('street1')}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Street 2"
                {...register('street2')}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="City"
                required
                {...register('city')}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY}
                    clearable
                    searchable
                    disabled={!isEditing}
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="ZIP"
                required
                {...register('zip')}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    searchable
                    disabled={!isEditing}
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="County"
                required
                {...register('county')}
                disabled={!isEditing}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={4}>Administrator Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator First Name"
                required
                {...register('adminfirstname', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator Last Name"
                required
                {...register('adminlastname', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator Email"
                required
                {...register('adminemail')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="adminphone"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label="Administrator Phone">
                    <Input
                      component={IMaskInput}
                      {...field}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                      disabled={!isEditing}
                    />
                  </Input.Wrapper>
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        {isEditing && (
          <Button type="submit" mt="md">
            Save Changes
          </Button>
        )}
      </form>
    </Container>
  );
};

export default PracticeInfoTab;
