import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextInput, Button, Container, Select, Grid, Paper, Title, Text, PasswordInput, Textarea } from '@mantine/core';
import { STATES_ARRAY } from '../types/members.types';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';

interface IpaMemberSignupProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const IpaMemberSignup: React.FC<IpaMemberSignupProps> = ({
  initialData,
  onFormSubmit,
}) => {
  const { control, register, handleSubmit, reset, watch, formState: { errors } } = useForm({
    defaultValues: initialData,
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password'); // Watch password for confirmation validation

  const onSubmit = async (data: any) => {
    // Cast email to lowercase and remove password confirmation before submission
    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
      birthday: new Date(data.birthday)?.toISOString().slice(0, 10),
      licenseexpiration: new Date(data.licenseexpiration)
        ?.toISOString()
        .slice(0, 10),
    };
    delete submissionData.passwordConfirmation; // Remove password confirmation field from submission
  
    try {
      const response = await httpClient.post('/api/members/register', submissionData);
      const addUserResult = response.data.addUserResult;
  
      if (onFormSubmit) {
        onFormSubmit(response.data);
      }
  
      notifications.show({
        title: 'Success',
        message: 'Physician registered successfully',
        color: 'green',
        position: 'bottom-right',
      });
  
      // Navigate to confirmation page
      navigate('/insurance/register/confirmation', {
        state: { addUserResult }, // Pass the result as state
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit physician form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Login Information</Title>
          <Text mt="xs" size="sm" color="dimmed">
            This information will be used to create your account.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <PasswordInput
                label="Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                {...register('password', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Confirm Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                {...register('passwordConfirmation', {
                  required: true,
                  validate: (value) => value === password || 'Passwords do not match',
                })}
                error={errors.passwordConfirmation ? 'Passwords do not match' : null}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Personal Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Medical License Number"
                required
                {...register('licensenumber', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="licensestate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Medical License State"
                    data={STATES_ARRAY}
                    clearable
                    searchable
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="licenseexpiration"
                control={control}
                render={({ field }) => (
                  <DateInput
                    label="License Expiration Date"
                    placeholder="License Expiration Date"
                    value={field.value ? new Date(field.value + 'T00:00:00') : null}
                    onChange={(date) =>
                      field.onChange(date ? date.toISOString().slice(0, 10) : null)
                    }
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="birthday"
                control={control}
                render={({ field }) => (
                  <DateInput
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    value={field.value ? new Date(field.value + 'T00:00:00') : null}
                    onChange={(date) =>
                      field.onChange(date ? date.toISOString().slice(0, 10) : null)
                    }
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="NPI"
                required
                maxLength={10}
                {...register('npi', { required: true })}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Questions or Comments</Title>
          <Text mt="xs" size="sm" color="dimmed">
          If you have any questions, comments, concerns about your registration or WeCare IPA, or simply want to share your thoughts, feel free to leave us a message below.
          </Text>
          <Textarea
            placeholder="Please write any questions or comments you have here..."
            minRows={4}
            {...register('message')}
          />
        </Paper>

        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default IpaMemberSignup;
