import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Title,
  Grid,
  Card,
  Text,
  Anchor,
} from '@mantine/core';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(
  'pk_test_51PdGuuHon8D4YU7NG1z8ZO4l3r67GXdiz9qd0BvJ44mKe9K2ZVJeubLskFrD43HwsydlsCMUZHzv6WO9NBFr9Q7500aNK8nczN',
);

const PaymentInformationTab: React.FC = () => {
  const { organizationId } = useOrganizationContext();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [monthlyAmount, setMonthlyAmount] = useState<number | null>(null);
  const [resetPaymentMethod, setResetPaymentMethod] = useState(false);
  const [paymentCustomerId, setPaymentCustomerId] = useState<number>(null);
  const httpClient = useHttpClient();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await httpClient.get(
          '/api/stripe/check-subscription',
          {
            params: { organizationId },
          },
        );
        const { hasSubscription } = response.data;
        if (hasSubscription) {
          const subscriptionData = response.data.subscription;
          setSubscriptionExists(true);
          setPaymentCustomerId(subscriptionData.customerId);
          setMonthlyAmount(subscriptionData.monthly_amount);
        } else {
          setSubscriptionExists(false);
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    };

    if (!resetPaymentMethod) {
      checkSubscription();
    }
  }, []);

  const handleStartPaymentSetup = async () => {
    try {
      const response = await httpClient.post(
        '/api/stripe/create-setup-intent',
        {
          email: 'test@test.com',
          username: 'stripetest',
        },
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
      setResetPaymentMethod(true);
    } catch (error) {
      console.error('Error creating setup intent:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to initialize payment setup',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <Paper withBorder shadow="xs" p="md">
        <Grid justify="space-between" align="center">
          <Grid.Col span={11}>
            <Title order={3}>Payment Information</Title>
          </Grid.Col>
        </Grid>
        {subscriptionExists && !resetPaymentMethod ? (
          <Card shadow="sm" padding="lg">
            <Text>Monthly Payment Due: ${monthlyAmount}</Text>
            <Text
              component="a"
              href="#"
              color="blue"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                marginTop: '1em',
              }}
              onClick={handleStartPaymentSetup}
            >
              Reset Payment Method
            </Text>
          </Card>
        ) : clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm
              clientSecret={clientSecret}
              paymentCustomerId={paymentCustomerId}
            />
          </Elements>
        ) : (
          <Button onClick={handleStartPaymentSetup}>Start Payment Setup</Button>
        )}
      </Paper>
    </Container>
  );
};

export default PaymentInformationTab;
