import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Select,
  MultiSelect,
  Input,
  Paper,
  Title,
  Group,
  Collapse,
  Text,
  PasswordInput,
  Textarea,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { specialties } from '../../types/specialties.types';
import { STATES_ARRAY } from '../../types/members.types';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';

interface ProviderPhysicianFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const ProviderPhysicianForm: React.FC<ProviderPhysicianFormProps> = ({
  initialData,
  onFormSubmit,
}) => {
  const { control, register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues: { practices: [], ...initialData },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'practices',
  });
  const [collapsedIndices, setCollapsedIndices] = useState(fields.map(() => true));
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password'); // Watching the password field for confirmation validation

  const onSubmit = async (data: any) => {
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message: 'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-right',
      });
      return;
    }
    
    // Cast email to lowercase and remove password confirmation before submission
    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
      primaryspecialty: data.primaryspecialty.specialty,
      primarysubspecialties: data.primaryspecialty.subspecialty,
      secondaryspecialty: data.secondaryspecialty.specialty,
      secondarysubspecialties: data.secondaryspecialty.subspecialty,
    };
    delete submissionData.passwordConfirmation; // Remove password confirmation field from submission

    try {
      const response = await httpClient.post('/api/provider/physician/register', submissionData);

      if (onFormSubmit) {
        onFormSubmit(response.data);
      }

      notifications.show({
        title: 'Success',
        message: 'Physician pre-registered successfully',
        color: 'green',
        position: 'bottom-right',
      });

      // Navigate to confirmation page
      navigate('/insurance/register/confirmation', {
        state: {
          addUserResult: response.data.addUserResult,
          physicianExists: response.data.physicianExists,
        },
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to pre-register physician',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  const primarySpecialty = watch('primaryspecialty.specialty');
  const secondarySpecialty = watch('secondaryspecialty.specialty');

  useEffect(() => {
    setValue('primaryspecialty.subspecialty', []);
  }, [primarySpecialty, setValue]);

  useEffect(() => {
    setValue('secondaryspecialty.subspecialty', []);
  }, [secondarySpecialty, setValue]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Login Information</Title>
          <Text mt="xs" size="sm" color="dimmed">
            This information will be used to create your account.  Please choose a password of at least "Good" strength.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
            <PasswordInput
                label="Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                maxLength={20}
                {...register('password', { required: true })}
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score) => setPasswordStrengthScore(score)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Confirm Password"
                required
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                {...register('passwordConfirmation', {
                  required: true,
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                })}
                error={errors.passwordConfirmation ? 'Passwords do not match' : null}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Personal Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label="Phone">
                    <Input
                      component={IMaskInput}
                      {...field}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                    />
                  </Input.Wrapper>
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="caretype"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Care Type"
                    data={[
                      { value: 'Primary', label: 'Primary' },
                      { value: 'Specialty', label: 'Specialty' },
                      { value: 'Both', label: 'Both' },
                    ]}
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="primaryspecialty.specialty"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Primary Specialty"
                    data={Object.keys(specialties)}
                    clearable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="primaryspecialty.subspecialty"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    label="Primary Subspecialties"
                    data={primarySpecialty ? specialties[primarySpecialty] : []}
                    clearable
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="secondaryspecialty.specialty"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Secondary Specialty"
                    data={Object.keys(specialties)}
                    clearable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="secondaryspecialty.subspecialty"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    label="Secondary Subspecialties"
                    data={secondarySpecialty ? specialties[secondarySpecialty] : []}
                    clearable
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Medical License"
                required
                {...register('medicallicense', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="medicallicensestate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Medical License State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="DEA"
                required
                maxLength={9}
                {...register('dea', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="deastate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="DEA State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="NPI"
                maxLength={10}
                required
                {...register('npi', { required: true })}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        {/* Practice Locations Section */}
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Practice Locations</Title>

          {fields.map((field, index) => (
            <Paper key={field.id} withBorder shadow="xs" p="md" mt="md">
              <Group>
                <h4>Practice Location {index + 1}</h4>
                <Group>
                  <Button size="xs" onClick={() => toggleCollapse(index)}>
                    {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                  </Button>
                  <Button color="red" size="xs" onClick={() => remove(index)}>
                    Remove
                  </Button>
                </Group>
              </Group>
              <Collapse in={!collapsedIndices[index]}>
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Practice Name"
                      required
                      {...register(`practices.${index}.name`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Practice Email"
                      required
                      {...register(`practices.${index}.email`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Tax Identification Number"
                      required
                      maxLength={9}
                      {...register(`practices.${index}.tin`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Type 2 Organization NPI"
                      maxLength={10}
                      required
                      {...register(`practices.${index}.npi`)}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                      }}
                    />
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={12}>
                    <TextInput
                      label="Practice Website"
                      maxLength={10}
                      required
                      {...register(`practices.${index}.practicewebsite`)}
                    />
                  </Grid.Col>
                </Grid>

                <Paper shadow="xs" p="md" withBorder mt="md">
                  <Title order={4}>Address Information</Title>
                  <TextInput
                    label="Street 1"
                    required
                    {...register(`practices.${index}.street1`)}
                  />
                  <TextInput
                    label="Street 2"
                    {...register(`practices.${index}.street2`)}
                  />
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="City"
                        required
                        {...register(`practices.${index}.city`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`practices.${index}.state`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="State"
                            data={STATES_ARRAY.map((state) => ({
                              value: state,
                              label: state,
                            }))}
                            clearable
                            searchable
                            required
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="ZIP"
                        required
                        maxLength={10}
                        {...register(`practices.${index}.zip`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`practices.${index}.country`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Country"
                            data={[{ value: 'US', label: 'United States' }]}
                            clearable
                            required
                          />
                        )}
                      />
                    </Grid.Col>
                  </Grid>
                  <TextInput
                    label="County"
                    required
                    {...register(`practices.${index}.county`)}
                  />
                </Paper>

                <Paper shadow="xs" p="md" withBorder mt="md">
                  <Title order={4}>Office Manager Information</Title>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Office Manager First Name"
                        required
                        {...register(`practices.${index}.adminfirstname`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Office Manager Last Name"
                        required
                        {...register(`practices.${index}.adminlastname`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Office Manager Email"
                        required
                        {...register(`practices.${index}.adminemail`)}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`practices.${index}.adminphone`}
                        control={control}
                        render={({ field }) => (
                          <Input.Wrapper label="Office Manager Phone">
                            <Input
                              component={IMaskInput}
                              {...field}
                              mask="(000) 000-0000"
                              placeholder="(XXX) XXX-XXXX"
                            />
                          </Input.Wrapper>
                        )}
                      />
                    </Grid.Col>
                  </Grid>
                </Paper>
              </Collapse>
            </Paper>
          ))}

          <Button
            mt="md"
            onClick={() =>
              append({
                name: '',
                tin: '',
                npi: '',
                email: '',
                street1: '',
                city: '',
                state: '',
                zip: '',
                county: '',
                adminfirstname: '',
                adminlastname: '',
                adminemail: '',
                adminphone: '',
              })
            }
          >
            Add Practice Location
          </Button>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Questions or Comments</Title>
          <Text mt="xs" size="sm" color="dimmed">
          If you have any questions, comments, concerns about your registration or Ryze Health, or simply want to share your thoughts, feel free to leave us a message below.
          </Text>
          <Textarea
            placeholder="Please write any questions or comments you have here..."
            minRows={4}
            {...register('message')}
          />
        </Paper>

        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default ProviderPhysicianForm;
