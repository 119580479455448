import React, { useState } from 'react';
import { Tabs, Grid, Button, Group, Title } from '@mantine/core';
import PersonalInformationTab from './PersonalInformationTab';
import FamilyMemberManagementTab from './FamilyManagementTab';
import HomeIcon from '../HomeIcon';
import LogoutIcon from '../LogoutIcon';
import DarkModeToggle from '../DarkModeToggle';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import PrivacyPolicyModal from './PrivacyPolicy';
import { notifications } from '@mantine/notifications';

const InsuranceMemberPortal: React.FC = () => {
  const { member } = useInsuranceMemberContext();
  const [modalOpened, setModalOpened] = useState(false);
  const [isFamilyFieldsComplete, setIsFamilyFieldsComplete] = useState(true); //temp true until implement check

  const checkFieldsComplete = () => {
    const requiredPersonalInfoFields = ['firstname', 'lastname', 'birthdate', 'email'];
    const isPersonalInfoComplete = requiredPersonalInfoFields.every(
      (field) => member && member[field as keyof typeof member] && member[field as keyof typeof member] !== ''
    );

    if (!isPersonalInfoComplete || !isFamilyFieldsComplete) {
      notifications.show({
        title: 'Incomplete Information',
        message: 'Please complete all required fields before proceeding.',
        color: 'red',
        position: 'bottom-right',
      });
      return false;
    }
    return true;
  };

  const handleOpenModal = () => {
    if (checkFieldsComplete()) {
      setModalOpened(true);
    }
  };

  return (
    <>
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>

      <Title
        order={1}
        style={{
          textAlign: 'center',
          marginBottom: '2rem',
        }}
      >
        Member Portal
      </Title>

      <Group justify="right" mb="md">
        <Button color="green" onClick={handleOpenModal}>
          Complete Registration
        </Button>
      </Group>

      <PrivacyPolicyModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      />

      <Tabs defaultValue="personal-info">
        <Tabs.List>
          <Tabs.Tab value="personal-info">Personal Info</Tabs.Tab>
          <Tabs.Tab value="family-member-management">
            Family Member Management
          </Tabs.Tab>
          <Tabs.Tab value="payment-information">Payment Information</Tabs.Tab>
          <Tabs.Tab value="copay-deductible-tracking">
            Copay & Deductible Tracking
          </Tabs.Tab>
          <Tabs.Tab value="financial-statements">Financial Statements</Tabs.Tab>
          <Tabs.Tab value="settings">Settings</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="personal-info">
          {member && <PersonalInformationTab />}
        </Tabs.Panel>
        <Tabs.Panel value="family-member-management">
          {member && (
            <FamilyMemberManagementTab onFieldsCompleteChange={setIsFamilyFieldsComplete} />
          )}
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default InsuranceMemberPortal;
