import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Input,
  Select,
  ActionIcon,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { STATES_ARRAY } from '../../types/members.types';
import { Pencil, Lock } from 'tabler-icons-react';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import useHttpClient from '../hooks/useHttpClient';

interface PersonalInformationTabProps {
  onFormSubmit?: (data: any) => void;
}

const PersonalInformationTab: React.FC<PersonalInformationTabProps> = ({
  onFormSubmit,
}) => {
  const { subscriberMemberId } = useInsuranceMemberContext();
  const { control, register, handleSubmit, reset } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const httpClient = useHttpClient();

  // Fetch personal information for the member
  const fetchMemberData = async () => {
    try {
      const response = await httpClient.get(
        `/api/subscriber/${subscriberMemberId}`,
      );
      const memberData = response.data;
      reset(memberData); // Populate the form with fetched data
    } catch (error) {
      console.error('Error fetching member data:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to load member data',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  // Submit updated personal information
  const onSubmit = async (data: any) => {
    try {
      await httpClient.put(`/api/subscriber/${subscriberMemberId}`, data);

      notifications.show({
        title: 'Success',
        message: 'Personal information updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
      setIsEditing(false);

      if (onFormSubmit) {
        onFormSubmit(data);
      }
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update personal information',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (subscriberMemberId) {
      fetchMemberData();
    }
  }, [subscriberMemberId]);

  return (
    <Container>
      <Paper withBorder shadow="xs" p="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid justify="space-between" align="center">
            <Grid.Col span={11}>
              <Title order={3}>Personal Information</Title>
            </Grid.Col>
            <Grid.Col
              span={1}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <ActionIcon
                onClick={() => setIsEditing((prev) => !prev)}
                variant="outline"
                color="blue"
              >
                {isEditing ? <Lock size={16} /> : <Pencil size={16} />}
              </ActionIcon>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                {...register('firstname', { required: true })}
                disabled={!isEditing}
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                {...register('lastname', { required: true })}
                disabled={!isEditing}
                required
              />
            </Grid.Col>
          </Grid>
          <TextInput
            label="Email"
            {...register('email', { required: true })}
            disabled
            required
          />
          <Controller
            name="birthdate"
            control={control}
            rules={{
              required: 'Date of birth is required',
              validate: (value) => {
                const selectedDate = new Date(value);
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Reset time part to compare only dates
                return (
                  selectedDate < today || 'Date of birth must be in the past'
                );
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <DateInput
                  label="Date of Birth"
                  value={
                    field.value ? new Date(field.value + 'T00:00:00') : null
                  }
                  onChange={(date) =>
                    field.onChange(
                      date ? date.toISOString().slice(0, 10) : null,
                    )
                  }
                  disabled={!isEditing}
                  clearable
                  required
                />
                {error && <span style={{ color: 'red' }}>{error.message}</span>}
              </>
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: 'Phone number is required',
            }}
            render={({ field, fieldState: { error } }) => (
              <Input.Wrapper
                label={
                  <>
                    Phone Number <span style={{ color: 'red' }}>*</span>
                  </>
                }
                error={error?.message}
              >
                <Input
                  component={IMaskInput}
                  {...field}
                  mask="(000) 000-0000"
                  placeholder="(XXX) XXX-XXXX"
                  disabled={!isEditing}
                  required
                />
              </Input.Wrapper>
            )}
          />
          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3}>Address Information</Title>
            <TextInput
              label="Street 1"
              {...register('street1')}
              disabled={!isEditing}
              required
            />
            <TextInput
              label="Street 2"
              {...register('street2')}
              disabled={!isEditing}
            />
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="City"
                  {...register('city')}
                  disabled={!isEditing}
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="State"
                      data={STATES_ARRAY}
                      clearable
                      required
                      searchable
                      disabled={!isEditing}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="ZIP"
                  {...register('zip')}
                  disabled={!isEditing}
                  required
                  maxLength={5}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="County"
                  {...register('county')}
                  disabled={!isEditing}
                  required
                />
              </Grid.Col>
            </Grid>
          </Paper>
          {isEditing && (
            <Button type="submit" mt="md">
              Submit
            </Button>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default PersonalInformationTab;
