import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Group,
  Collapse,
  Paper,
  Grid,
  Select,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';

const ProfessionalIDs: React.FC = () => {
  const { memberId } = useMemberContext();
  const { control, register, handleSubmit, reset } = useForm({});

  const {
    fields: npiFields,
    append: appendNpi,
    remove: removeNpi,
  } = useFieldArray({
    control,
    name: 'npis',
  });

  const {
    fields: medicalLicenseFields,
    append: appendMedicalLicense,
    remove: removeMedicalLicense,
  } = useFieldArray({
    control,
    name: 'medicalLicenses',
  });

  const {
    fields: deaFields,
    append: appendDea,
    remove: removeDea,
  } = useFieldArray({
    control,
    name: 'deaNumbers',
  });

  const {
    fields: cdsFields,
    append: appendCds,
    remove: removeCds,
  } = useFieldArray({
    control,
    name: 'cdsNumbers',
  });

  const {
    fields: medicareFields,
    append: appendMedicare,
    remove: removeMedicare,
  } = useFieldArray({
    control,
    name: 'medicareNumbers',
  });

  const {
    fields: medicaidFields,
    append: appendMedicaid,
    remove: removeMedicaid,
  } = useFieldArray({
    control,
    name: 'medicaidNumbers',
  });

  const {
    fields: ecfmgFields,
    append: appendEcfmg,
    remove: removeEcfmg,
  } = useFieldArray({
    control,
    name: 'ecfmg',
  });

  const {
    fields: usmleFields,
    append: appendUsmle,
    remove: removeUsmle,
  } = useFieldArray({
    control,
    name: 'usmle',
  });

  const {
    fields: workerscompFields,
    append: appendWorkersComp,
    remove: removeWorkersComp,
  } = useFieldArray({
    control,
    name: 'workerscomp',
  });

  const [collapsedNpis, setCollapsedNpis] = useState(
    npiFields.map(() => true),
  );
  const [collapsedMedicalLicenses, setCollapsedMedicalLicenses] = useState(
    medicalLicenseFields.map(() => true),
  );
  const [collapseddea, setCollapseddea] = useState(deaFields.map(() => true));
  const [collapsedcds, setCollapsedcds] = useState(cdsFields.map(() => true));
  const [collapsedMedicare, setCollapsedMedicare] = useState(
    medicareFields.map(() => true),
  );
  const [collapsedMedicaid, setCollapsedMedicaid] = useState(
    medicaidFields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>([]);
  const httpClient = useHttpClient();

  const toggleCollapse = (
    setCollapsed: React.Dispatch<React.SetStateAction<boolean[]>>,
    index: number,
  ) => {
    setCollapsed((prev) => {
      const newCollapsed = [...prev];
      newCollapsed[index] = !newCollapsed[index];
      return newCollapsed;
    });
  };

  const fetchProfessionalIDs = async () => {
    try {
      const response = await httpClient.get(`/api/professionalids/${memberId}`);
      const fetchedProfessionalIDs = response.data;

      const mapToFormValues = (items: any[], type: string) => {
        return items
          .filter((item) => item.type === type)
          .map((item) => ({
            ...item,
            issuedate: item.issuedate ? new Date(item.issuedate + 'T00:00:00') : null,
            expirationdate: item.expirationdate
              ? new Date(item.expirationdate + 'T00:00:00')
              : null,
          }))
          .sort((a, b) => {
            const expirationDateComparison =
              new Date(a.expirationdate).getTime() -
              new Date(b.expirationdate).getTime();
            if (expirationDateComparison !== 0) return expirationDateComparison;
            const issueDateComparison =
              new Date(a.issuedate).getTime() - new Date(b.issuedate).getTime();
            if (issueDateComparison !== 0) return issueDateComparison;
            const idNumberComparison = a.idnumber.localeCompare(b.idnumber);
            if (idNumberComparison !== 0) return idNumberComparison;
            return a.id - b.id;
          });
      };

      const formValues = {
        npis: mapToFormValues(fetchedProfessionalIDs, 'NPI'),
        medicalLicenses: mapToFormValues(fetchedProfessionalIDs, 'Medical License'),
        deaNumbers: mapToFormValues(fetchedProfessionalIDs, 'DEA'),
        cdsNumbers: mapToFormValues(fetchedProfessionalIDs, 'CDS'),
        medicareNumbers: mapToFormValues(fetchedProfessionalIDs, 'Medicare'),
        medicaidNumbers: mapToFormValues(fetchedProfessionalIDs, 'Medicaid'),
        ecfmg: mapToFormValues(fetchedProfessionalIDs, 'ECFMG'),
        usmle: mapToFormValues(fetchedProfessionalIDs, 'USMLE'),
        workerscomp: mapToFormValues(fetchedProfessionalIDs, 'WorkersComp'),
      };

      reset(formValues);
      setInitialData(fetchedProfessionalIDs); // Set initial data here
    } catch (error) {
      console.error('Error fetching professional IDs:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch professional IDs',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    fetchProfessionalIDs();
  }, [memberId]);

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);

    const formatData = (items: any[], type: string) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
        type,
        issuedate: item.issuedate
          ? new Date(item.issuedate + 'T00:00:00').toISOString()
          : null,
        expirationdate: item.expirationdate
          ? new Date(item.expirationdate + 'T00:00:00').toISOString()
          : null,
      }));
    };

    const formattedData = [
      ...formatData(data.medicalLicenses, 'Medical License'),
      ...formatData(data.npis, 'NPI'),
      ...formatData(data.deaNumbers, 'DEA'),
      ...formatData(data.cdsNumbers, 'CDS'),
      ...formatData(data.medicareNumbers, 'Medicare'),
      ...formatData(data.medicaidNumbers, 'Medicaid'),
      ...formatData(data.ecfmg, 'ECFMG'),
      ...formatData(data.usmle, 'USMLE'),
      ...formatData(data.workerscomp, 'WorkersComp'),
    ];

    const createProfessionalID = async (idData: any) => {
      const response = await httpClient.post('/api/professionalids', idData);
      return response.data;
    };

    const updateProfessionalID = async (idData: any) => {
      const response = await httpClient.put(
        `/api/professionalids/${idData.id}`,
        idData,
      );
      return response.data;
    };

    const deleteProfessionalID = async (id: number) => {
      await httpClient.delete(`/api/professionalids/${id}`);
      console.log('delete response');
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData.map((item: any) => item.id));
    const currentIds = new Set<number>(
      formattedData.map((item: any) => item.id),
    );
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((item: any) => !item.id || item.id === 0)
      .map((item: any) => createProfessionalID(item));
    const updateTasks = formattedData
      .filter((item: any) => item.id && item.id > 0 && initialIds.has(item.id))
      .map((item: any) => updateProfessionalID(item));

    const deleteTasks = deletedIds.map((id) => deleteProfessionalID(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchProfessionalIDs(); // Refetch to sync with the latest backend state

      notifications.show({
        title: 'Success',
        message: 'Professional IDs updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting professional IDs form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit professional IDs',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        {npiFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>NPI {index + 1}</h3>
              <Button
                onClick={() =>
                  toggleCollapse(setCollapsedNpis, index)
                }
                size="xs"
              >
                {collapsedNpis[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button
                color="red"
                onClick={() => removeNpi(index)}
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedNpis[index]}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="NPI"
                    maxLength={10}
                    {...register(`npis.${index}.idnumber`)}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              appendNpi({
                type: 'NPI',
              })
            }
          >
            Add NPI
          </Button>
        </Group>

        {medicalLicenseFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Medical License {index + 1}</h3>
              <Button
                onClick={() =>
                  toggleCollapse(setCollapsedMedicalLicenses, index)
                }
                size="xs"
              >
                {collapsedMedicalLicenses[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button
                color="red"
                onClick={() => removeMedicalLicense(index)}
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedMedicalLicenses[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Medical License Number"
                    {...register(`medicalLicenses.${index}.idnumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicalLicenses.${index}.status`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Status"
                        data={[
                          { value: 'Active', label: 'Active' },
                          { value: 'Expired', label: 'Expired' },
                          { value: 'Pending', label: 'Pending' },
                          { value: 'Revoked', label: 'Revoked' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicalLicenses.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicalLicenses.${index}.currentlypracticing`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Currently Practicing"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicalLicenses.${index}.issuedate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          label="Issue Date"
                          placeholder="Issue Date"
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicalLicenses.${index}.expirationdate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          label="Expiration Date"
                          placeholder="Expiration Date"
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              appendMedicalLicense({
                type: 'Medical License',
                idnumber: '',
                status: '',
                state: '',
                currentlypracticing: '',
                issuedate: null,
                expirationdate: null,
              })
            }
          >
            Add Medical License
          </Button>
        </Group>

        {deaFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>DEA {index + 1}</h3>
              <Button
                onClick={() => toggleCollapse(setCollapseddea, index)}
                size="xs"
              >
                {collapseddea[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => removeDea(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapseddea[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="DEA Number (Drug Enforcement Administration)"
                    {...register(`deaNumbers.${index}.idnumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`deaNumbers.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`deaNumbers.${index}.issuedate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          label="Issue Date"
                          placeholder="Issue Date"
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`deaNumbers.${index}.expirationdate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          label="Expiration Date"
                          placeholder="Expiration Date"
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              appendDea({
                type: 'DEA',
                idnumber: '',
                state: '',
                expirationdate: null,
                issuedate: null,
              })
            }
          >
            Add DEA Number
          </Button>
        </Group>

        {cdsFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>CDS {index + 1}</h3>
              <Button
                onClick={() => toggleCollapse(setCollapsedcds, index)}
                size="xs"
              >
                {collapsedcds[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => removeCds(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedcds[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="CDS Number (Controlled Dangerous Substances)"
                    {...register(`cdsNumbers.${index}.idnumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`cdsNumbers.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`cdsNumbers.${index}.issuedate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          label="Issue Date"
                          placeholder="Issue Date"
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`cdsNumbers.${index}.expirationdate`}
                    control={control}
                    render={({ field }) => {
                      const parsedDate = field.value && field.value.length === 10
                        ? new Date(field.value + 'T00:00:00')
                        : new Date(field.value);

                      return (
                        <DateInput
                          {...field}
                          label="Expiration Date"
                          placeholder="Expiration Date"
                          value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                          onChange={(date) =>
                            field.onChange(date ? date.toISOString().slice(0, 10) : null)
                          }
                          clearable
                        />
                      );
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              appendCds({
                type: 'CDS',
                idnumber: '',
                state: '',
                issuedate: null,
                expirationdate: null,
              })
            }
          >
            Add CDS Number
          </Button>
        </Group>

        {medicareFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Medicare {index + 1}</h3>
              <Button
                onClick={() => toggleCollapse(setCollapsedMedicare, index)}
                size="xs"
              >
                {collapsedMedicare[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button
                color="red"
                onClick={() => removeMedicare(index)}
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedMedicare[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Medicare Number"
                    {...register(`medicareNumbers.${index}.idnumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicareNumbers.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              appendMedicare({
                type: 'Medicare',
                idnumber: '',
                state: '',
              })
            }
          >
            Add Medicare Number
          </Button>
        </Group>

        {medicaidFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Medicaid {index + 1}</h3>
              <Button
                onClick={() => toggleCollapse(setCollapsedMedicaid, index)}
                size="xs"
              >
                {collapsedMedicaid[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button
                color="red"
                onClick={() => removeMedicaid(index)}
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedMedicaid[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Medicaid Number"
                    {...register(`medicaidNumbers.${index}.idnumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`medicaidNumbers.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              appendMedicaid({
                type: 'Medicaid',
                idnumber: '',
                state: '',
              })
            }
          >
            Add Medicaid Number
          </Button>
        </Group>

        {ecfmgFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>ECFMG</h3>
              <Button onClick={() => removeEcfmg(index)} color="red" size="xs">
                Remove
              </Button>
            </Group>
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="ECFMG Number (Educational Commission for Foreign Medical Graduates)"
                  {...register(`ecfmg.${index}.idnumber`)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name={`ecfmg.${index}.issuedate`}
                  control={control}
                  render={({ field }) => {
                    const parsedDate = field.value && field.value.length === 10
                      ? new Date(field.value + 'T00:00:00')
                      : new Date(field.value);

                    return (
                      <DateInput
                        {...field}
                        label="ECFMG Issue Date"
                        placeholder="ECFMG Issue Date"
                        value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString().slice(0, 10) : null)
                        }
                        clearable
                      />
                    );
                  }}
                />
              </Grid.Col>
            </Grid>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          {ecfmgFields.length === 0 && (
            <Button
              onClick={() =>
                appendEcfmg({
                  type: 'ECFMG',
                  idnumber: '',
                  issuedate: null,
                })
              }
            >
              Add ECFMG
            </Button>
          )}
        </Group>

        {usmleFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>USMLE</h3>
              <Button onClick={() => removeUsmle(index)} color="red" size="xs">
                Remove
              </Button>
            </Group>
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="USMLE Number (United States Medical Licensing Examination)"
                  {...register(`usmle.${index}.idnumber`)}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name={`usmle.${index}.issuedate`}
                  control={control}
                  render={({ field }) => {
                    const parsedDate = field.value && field.value.length === 10
                      ? new Date(field.value + 'T00:00:00')
                      : new Date(field.value);

                    return (
                      <DateInput
                        {...field}
                        label="USMLE Exam Date"
                        placeholder="USMLE Exam Date"
                        value={parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString().slice(0, 10) : null)
                        }
                        clearable
                      />
                    );
                  }}
                />
              </Grid.Col>
            </Grid>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          {usmleFields.length === 0 && (
            <Button
              onClick={() =>
                appendUsmle({
                  type: 'USMLE',
                  idnumber: '',
                  issuedate: null,
                })
              }
            >
              Add USMLE
            </Button>
          )}
        </Group>

        {workerscompFields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Workers Compensation</h3>
              <Button
                onClick={() => removeWorkersComp(index)}
                color="red"
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <TextInput
              label="Workers Compensation Number"
              {...register(`workerscomp.${index}.idnumber`)}
            />
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          {workerscompFields.length === 0 && (
            <Button
              onClick={() =>
                appendWorkersComp({
                  type: 'WorkersComp',
                  idnumber: '',
                })
              }
            >
              Add Workers Compensation
            </Button>
          )}
        </Group>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default ProfessionalIDs;
